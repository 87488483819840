import React from "react";
import { Helmet } from "react-helmet";

const title =
  "The do's and dont's of Freshers Week | Articles | Manchester Students Group";
const description = "";

export default function FreshersWeekDoAndDonts(p) {
  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <div className="blog-post">
        <img src="/dosandonts.jpg" alt="" />
        <h1>THE DO’S AND DONT’S OF FRESHERS WEEK</h1>
        <p>Euan Walker / Guide / 3 minute read</p>

        <p>
          As we’ve reached another September, many of you will be arriving at
          uni for the first time. There’s a good chance you’re feeling pretty
          anxious with such big changes coming up (especially if you have never
          been to this city or even this country), so we thought we would give
          you a short list of do’s and don’ts for your first few weeks that we
          probably wanted to hear when we were Freshers ourselves. Take these
          with a pinch of salt as uni is a different experience for everyone, in
          what will most likely be the craziest months of your life.
        </p>

        <h2>DO’S</h2>

        <h3>TALK TO PEOPLE:</h3>
        <p>
          It’s worth remembering that everyone is in the same situation as you,
          moving to a new city where you know only a few or absolutely no people
          at all. Everybody should be open to talk to you in some way or
          another, it may just take a painfully cringey icebreaker to start it.
          If you are in halls, spend time in the kitchen or common room, this is
          your only real communal space so make the most of it. Ask your
          flatmates about themselves and conversations should hopefully spring
          up as you find out more about each other. In no time you will be
          chatting about anything from Taylor Swift to who will make this
          season’s top four.
        </p>

        <h3>TRY NEW THINGS:</h3>
        <p>
          Break your comfort zone. Say yes to everything (within reason). This
          is your chance to experience new things at a rate like never before.
          Make the most of your new home by exploring our huge unique city and
          nearby areas. Music wise - from house to heavy metal there is
          something for everyone, while fashionistas and foodies will find a
          wealth of options throughout the city centre (Afflecks and This and
          That are personal favourites). There is a decent chance that
          Manchester is different to wherever you are from so try something new!
          If you are invited to Hidden but you don’t think garage is your thing,
          at least give it a go, you will make memories either way and you may
          end up finding a new interest.
        </p>

        <h3>GO TO FRESHERS FAIRS</h3>
        <p>
          It’s easy to spend every day of Freshers week with a crippling
          hangover but if possible do try to visit your Freshers’ fair for the
          freebies and discounts alone if you don’t find something more
          useful.Try the societies’ fair too and talk to as many as possible.
          All groups will happily take on complete beginners and this might be
          your chance to find a hidden passion that will change your life…or at
          least meet a friend! They also don’t expect everyone they speak with
          to sign up, so have no fear at all in having a chat with them.
        </p>

        <h3>EAT HEALTHILY</h3>
        <p>
          As tempting as it is to only eat fast food now you are away from home
          (Chester’s or Al-Zayn on the Curry Mile are my go-to’s), this will
          empty your bank account scarily quickly while ruining your physical
          and mental health. Cooking your own food is a satisfying, money-saving
          alternative. A quick Google or TikTok search can give easy inspo if
          you have never tried cooking before, maybe try recreating family
          recipes if you’re feeling homesick.
        </p>

        <h3>GO TO INTRODUCTORY SESSIONS:</h3>
        <p>
          Controversial, but here you will meet your coursemates for the next
          three or more years. I promise you won’t be the only hungover person
          there so don’t let that put you off as it’s definitely worth making
          friends early doors.There will also most likely be a social of some
          sort on your course which is also worth attending, even if you know
          nobody there because many others will feel the exact same.
        </p>

        <h2>DONT’S</h2>

        <h3>GO OVERBOARD:</h3>
        <p>
          Do bear in mind that you are in a completely new setting with new
          people. Don’t overdo it, and be safe. If it is your first time doing
          something make sure you are in a safe, calm environment and monitor
          your limits and how you’re feeling. Don’t leave drinks unattended and
          remember that you do not need to do anything you don’t feel
          comfortable with. Don’t leave the ‘safe’ part of safe sex to the other
          person as STIs can unfortunately be a problem at uni. Have fun, but
          take care.
        </p>

        <h3>ISOLATE YOURSELF:</h3>
        <p>
          Expectations for your first couple of weeks can be high and there’s
          nothing wrong with feeling underwhelmed or homesick in your first few
          weeks - different people take different amounts of time to get used to
          their new home. Chat about how you are feeling with flatmates or
          coursemates and you may well find that you are not alone in feeling
          like this. Ring your parents/siblings/friends from home and check in
          on them, they will miss you! Look out for your new friends and those
          around you, just a simple “how are you?” could be all someone needs to
          hear before properly opening up for the first time.
        </p>

        <h3>STRESS:</h3>
        <p>
          Your first year at uni should be one of the most enjoyable periods of
          your life so try not to stress too much about everything, doing what
          you are comfortable with. Spend a week or two not worrying about
          academic work and getting used to your new surroundings and the people
          in them. It will be over before you know it, and you’ll wish you could
          do it all again.
        </p>
      </div>
    </main>
  );
}
